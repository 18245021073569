//init.js --- 
// 
// Filename     : init.js
// Description  : Initialization script for loading components.
// Author       : Christophe Burki
// Maintainer   : Christophe Burki
// Created      : Tue Aug 18 10:27:12 2015
// Version      : 1.0.0
// Last-Updated : Thu Sep 21 10:13:17 2017 (7200 CEST)
//           By : Jose Ramon del Rio
//     Update # : 47
// URL          : 
// Keywords     : 
// Compatibility: 
// 
//

//Commentary   : 
// 
// 
// 
//

//Change log:
// 
// 
//
// 
// This program is free software; you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation; either version 2, or (at your option)
// any later version.
// 
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
// 
// You should have received a copy of the GNU General Public License
// along with this program; see the file COPYING.  If not, write to the
// Free Software Foundation, Inc., 51 Franklin Street, Fifth
// ;; Floor, Boston, MA 02110-1301, USA.
// 
//

//Code         :

/* -------------------------------------------------------------------------- */

/* js and css base path could be defined in another preloaded file.
   For we use this feature for the wordpress plugin. */

if (fgServerUrl == undefined) {
    var fgServerUrl = '';
}
if (fgHashPrefix == undefined) {
    var fgHashPrefix = '';
}

var jsPath = 'js/FinGraphs3/';
var cssPath = 'css/FinGraphs3/';

var loader = jsLoader;
loader.load(window.location.protocol + '//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css', 'css', false);
loader.load('css/fonts/font-awesome-animation.min.css', 'css');
loader.load(cssPath + 'app_bundle.min.css');
loader.load(jsPath + 'app_bundle.min.js');

/* -------------------------------------------------------------------------- */

//
//init.js ends here
